<template>
  <div class="fs-exclude">
    <div class="text-xl font-semibold mb-8 mt-1">Update password</div>

    <transition-group name="fade" mode="out-in">
      <div
        v-if="passwordSuccess"
        key="password-success"
        class=" px-4 py-4  h-16 text-left text-teal-600  mx-auto rounded-lg mb-6 bg-teal-100  border-2 border-teal-400 flex space-x-3 items-center"
      >
        <font-awesome-icon
          :icon="['far', 'check']"
          class=" text-teal-500 text-xl"
        />
        <div>Your password has been updated successfully</div>
      </div>

      <div
        v-if="passwordError"
        key="detail-error"
        class="text-center max-w-96 text-red-500 bg-red-100 border-2 mb-6 border-red-400 rounded p-4 space-y-4"
      >
        {{ errorMessage }}. Please try again or contact us.
      </div>
    </transition-group>
    <div class="input-group">
      <label>Old Password</label>
      <password-input v-model="oldPassword" :canView="true" />
    </div>

    <div class="input-group">
      <label>New Password</label>
      <div class="text-gray-600 max-width-form">
        <div>
          To help keep you account secure please choose a new password.
        </div>
        <div class="mt-2 mb-4">
          - Minimum of 5 characters
        </div>
      </div>
      <password-input v-model="newPassword" :canView="true" :showValid="true" />
    </div>
    <div class="input-group">
      <label>Re-enter your new password</label>
      <password-input
        v-model="confirmNewPassword"
        :canView="true"
        :showValid="isSamePassword"
      />
    </div>
    <form @submit.prevent="updatePassword">
      <loading-button
        :is-loading="isLoading"
        :is-enabled="isEnabled"
        label="Change Password"
      />
    </form>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import PasswordInput from '@/components/auth/PasswordInput';
import { isEqual } from 'lodash/fp';
import http from '@/http';
export default {
  name: 'SecurityDetails',
  metaInfo: {
    title: 'Security Details - Settings'
  },
  data() {
    return {
      isLoading: false,
      passwordSuccess: false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      passwordError: false,
      errorMessage: 'There was an error updating your password'
    };
  },

  components: {
    LoadingButton,
    PasswordInput
  },
  computed: {
    isSamePassword() {
      return (
        isEqual(this.newPassword, this.confirmNewPassword) &&
        this.newPassword !== ''
      );
    },
    isEnabled() {
      return (
        this.isSamePassword &&
        this.oldPassword !== '' &&
        this.newPassword.length >= 5
      );
    }
  },
  methods: {
    updatePassword: function() {
      this.isLoading = true;
      this.passwordSuccess = false;
      this.passwordError = false;
      http
        .put('/me/password', {
          oldpassword: this.oldPassword,
          newpassword: this.newPassword
        })
        .then(() => {
          this.isLoading = false;
          this.passwordSuccess = true;
          this.oldPassword = '';
          this.newPassword = '';
          this.confirmNewPassword = '';
        })
        .catch(error => {
          console.log(error);
          this.passwordError = true;
          console.log('error', error);
          this.errorMessage = error.response.data.error.message;
          this.isLoading = false;
        });
    }
  },
  mounted() {}
};
</script>

<style lang="css" scoped>
.max-width-form {
  max-width: 360px;
}
</style>
